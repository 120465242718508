import Image from 'next/image';
import { useState } from "react";
import * as Dialog from '@radix-ui/react-dialog';


const assistantData = [
  {
    title: "Free Consultation",
    desc: "081387267440",
    icon: "/images/icons/assistant-consultation.png",
    url: "https://wa.me/6281387267440"
  },
  {
    title: "Customer Service",
    desc: "081288446533",
    icon: "/images/icons/assistant-cs.png",
    url: "https://wa.me/6281288446533"
  },
  {
    title: "Instagram",
    desc: "mooimom.id",
    icon: "/images/icons/assistant-ig.png",
    url: "https://www.instagram.com/mooimom.id/"
  },
  {
    title: "Tiktok",
    desc: "mooimom.id",
    icon: "/images/icons/assistant-tiktok.png",
    url: "https://www.tiktok.com/@mooimom.id"
  }
];

const Assistant = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Trigger asChild>
        <div className="fixed bottom-[30px] md:bottom-10 right-4 md:right-8 z-[30]">
          <Image
            src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/assistant.png"}
            alt="assistant icon"
            quality={100}
            width={60}
            height={60}
            className={`cursor-pointer h-[60px] w-[60px]`}
          />
        </div>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-mobileMask md:bg-desktopMask bg-opacity-50 md:bg-opacity-40 z-[60]" />
        <Dialog.Content className="fixed bottom-0 md:bottom-auto md:top-1/2 md:left-1/2 md:transform md:-translate-x-1/2 md:-translate-y-1/2 bg-white px-5 py-10 md:p-12 rounded-t-lg md:rounded-lg w-full md:w-fit md:max-w-1/2 mx-auto z-[65] outline-none focus:ring-transparent">
          <Dialog.Title />
          <Dialog.Description className="text-attributeTitle font-poppins">
            <div className="absolute top-4 right-4">
              <Image
                src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/assistant-close.png"}
                alt="close icon"
                width={18}
                height={18}
                quality={100}
                onClick={() => setIsOpen(false)}
                className="cursor-pointer"
              />
            </div>
            <div className="mt-4 space-y-6 md:px-10">
              {assistantData.map((data, index) => (
                <div
                  key={index}
                  className="py-[10px] pl-4 pr-4 md:pr-6 flex justify-between items-center space-x-4 cursor-pointer bg-assistantItemBg bg-opacity-[12%] rounded-[14px] w-full md:w-[315px]"
                  onClick={() => window.open(data.url, "_blank")}
                >
                  <div className="flex items-center space-x-4">
                    <Image
                      src={process.env.NEXT_PUBLIC_STATIC_SITE + data.icon}
                      alt="assistant icon"
                      width={42}
                      height={42}
                      quality={100}
                    />
                    <div>
                      <p className="text-assistantItemText font-poppins text-sm font-medium">{data.title}</p>
                      <p className="text-assistantItemDesc font-poppins text-xs">{data.desc}</p>
                    </div>
                  </div>
                  <Image
                    src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/assistant-arrow.png"}
                    alt="arrow icon"
                    quality={100}
                    width={12}
                    height={12}
                  />
                </div>
              ))}
            </div>
          </Dialog.Description>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

export default Assistant;
